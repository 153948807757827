@import "../../scss/variables/colors.module.scss";


.rowWrap {
    display: flex;
    overflow: hidden;
    word-break: break-all;
    // align-items: flex-end;
    // justify-content: center;
};

.icon {
    margin-right: 5px;
    font-size: 22px;
};

.conditentialIcon {
    position: absolute;
    top: 2px;
    left: 0px;
    background-color: white;
    height: 22px;
    width: 22px;
    display: flex;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    border-radius: 11px;
    border: 0.5px solid $verylightgrey;
};

.mobileTable {
    & thead[class*="ant-table-thead"] th {
        padding-left: 48px !important;
    };
    
    & td[class*="ant-table-cell"] {
        padding-left: 48px !important;
    };
}

.backBtn {
    position: absolute;
    z-index: 999;
    left: 16px;
    font-size: 20px;
}

.tableRow {
    cursor: pointer;
    user-select: none;
};
  
.tableRowLoading {
    cursor: progress;
    user-select: none;
    opacity: 0.3;
};

.logo {
    background-image: url('../../assets/img/logo_cut.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 40px;
    width: 175px;
    margin: 24px auto;
  }
