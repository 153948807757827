@import '../../scss/variables/colors.module.scss';

$lg: 44px;

.mainWrap {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeIcon {
  color: tomato;
  margin-left: 10px;
}

.image {
  width: 35%;
  height: 100vh;
  background: #c7b39b url('../../assets/img/unsplash_E2i7Hftb_rI.png');
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &_title {
    color: #ffffff !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 960px) {
  .signUpElem {
    width: 65%;
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 128px;
    background-color: #ffffff;
  
    .localizationSelect {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .signUpCard {
    width: 80%;
    padding-bottom: 32px;
  }
}

@media screen and (min-width: 960px) {
  .signUpElem {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  
    .localizationSelect {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .signUpCard {
    width: 30%;
    padding-right: 6px;
    overflow-y: scroll;
    
    div[class='ant-form-item css-dev-only-do-not-override-1pj3upy'] {
      margin-bottom: 14px;
    }
  }
}


.signUpForm {
  button[type='submit'] {
    width: 100%;
    height: $lg;
    background-color: $primary100;
    color: #ffffff;
    font-size: 18px;
    border-radius: 10px;
  }

  button[type='submit']:disabled {
    background-color: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.25);
  }

  .input {
    font-size: 18px;
    height: $lg;
    padding: {
      left: 20px;
      right: 20px;
    }
    border: 3px solid $primary100;
    border-radius: 10px;

    input {
      padding-left: 10px;
    }
  }

  .input:hover {
    border: 3px solid $primary100;
  }

  .itemIcon {
    font-size: 90%;
  }
}

.subTitle {
  color: #303468;
}

.primary {
  color: $primary100 !important;
  font-weight: 600;
}

.licenseButton {
  .link {
    color: $statusNew;
  }
}

.overlay {
  width: fit-content;
  max-width: 500px;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  border-radius: 6px;

  &_container {
    padding: 10px;
    position: relative;
  }

  &_close {
    position: absolute;
    top: 0;
    right: 0;
    color: $error;
  }
}
