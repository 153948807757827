@import "../../../../scss/variables/colors.module.scss";

.modalLicense {

  .scrollElem {
    padding: 20px 40px 0 40px;
    max-height: 85vh;
    overflow-y: scroll;
  }

  .paragraph {
    text-indent: 30px;
    padding: 5px 10px;
    text-align: justify;
  }

  ol {
    // padding: 0;
  }
  li {
    position: relative;
  }
  li::before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: -25px;
  }
  
  /* 1-й уровень */
  ol {
    counter-reset: num;
  }

  ol > li {
    counter-increment: num;
    font-weight: 600;
    padding-top: 20px;
  }

  ol > li::before {
    padding-top: 20px;
    content: counter(num) '.';
  }
  
  /* 2-й уровень */
  ol ol {
    counter-reset: num2;
  }

  ol ol > li {
    counter-increment: num2;
    font-weight: 400;
    padding-top: 0;
  }

  ol ol > li::before {
    padding-top: 0;
    content: counter(num) '.' counter(num2) '.';
  }

  /* Не нумерованный список */
  ul > li {
    font-weight: 400;
  }
};
