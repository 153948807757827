@import '../../scss/variables/colors.module.scss';

.protectedWrap {
  height: calc(100vh - 65px);
  overflow-y: scroll;
  padding: 0 20px;
};

.triffsWrap {
  width: 90%;
  height: 100vh;
}

.tariffsTable {
  // height: 100%;
  width: 100%;
  margin: 20px 0;
  padding: 0 4px;
  border-radius: 8px;
  border: 2px solid $primary100;

  td[class*=ant-table-cell]{
    height: 100%;
    border-bottom: 0 !important;
    padding: 12px  16px !important;
    overflow: hidden;
    text-wrap: wrap;
    text-overflow: ellipsis;
    word-break: normal;

    &:has(div.suitable_for) {
      vertical-align: top; // выравнивание по верхнему краю ячейки
    }
    &:has(div.electronic_signature) {
      vertical-align: top; // выравнивание по верхнему краю ячейки
    }

    &:not(:first-child) {
      border-left: 1px solid $borderColor;
    }
  }

  .tariff_name {
    font-size: 20px;
    font-weight: 600;
    color: $primary100;
    text-align: center;
  }

  .price {
    text-align: center;

    .forAYear {
      font-size: 12px;
      font-weight: 500;
    }
  }

}

.headerWrap {
  margin-top: 20px;
  display: flex;
  align-items: center !important;
  width: 100%;
  justify-content: flex-end;
  column-gap: 25px;

  .periodWrap {
    font-size: 14px;
    font-weight: 500;

    .switch {
      margin: 0 8px;
    }

    .switchSale {
      color: $primary100;
      background-color: $hover100;
      padding: 1px 3px;
      margin: 0 5px;
      border-radius: 4px;
    }
  }
}

.segmentedStyle {
  label[class*=ant-segmented-item]{
    font-weight: 500;
    color: $textcolor;
  }
}
